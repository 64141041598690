
import BagHead from './components/BagHead'
import BagNotify from './components/BagNotify'
import BagEmpty from './components/BagEmpty'
import BagGoods from './components/BagGoods'
import BagCard from './components/BagCard'
import BagCusRec from './components/BagCusRec'
import BagSafe from './components/BagSafe'
import BagAd from './components/BagAd'
import BagRec from './components/BagRec'
import BagSubmit from './components/BagSubmit'
import BagCoupon from './components/BagCoupon'
import BagViewed from './components/BagViewed'
import BagWish from './components/BagWish'
import OrderCoupon from './components/OrderCoupon'
import BagGiftList from './components/BagGiftList'

export default {
    name: 'Bag',
    // layout: 'main-footer',
    components: {
        BagHead,
        BagNotify,
        BagEmpty,
        BagGoods,
        BagCard,
        BagCusRec,
        BagSafe,
        BagAd,
        BagRec,
        BagSubmit,
        BagCoupon,
        BagViewed,
        BagWish,
        OrderCoupon,
        BagGiftList
    },
    asyncData({api, cookies, ...context}) {},
    props: {},
    data() {
        return {
            hasLogin: true,
            middleEmit: {},
            currency: {},
            goodsInfo: {},  // 所有商品信息
            goods: {        // 商品信息
                hasEmpty: true
            },
            amt: {},        // 下单信息
            edit: {         // 购物车编辑
                id: ''
            },
            cus: {},        // 与定制页联调数据
            referrer: {},
            loading:false,
            language:{},
            stripe:null,
            showNum:0,
            amount:0,
            elements:null,
            reNum:0
        }
    },
    head() {
        return this.setHead()
    },
    computed: {},
    watch: {},
    beforeRouteEnter (to, from, next) {
        next(vm => {
            vm.referrer = from
            return true
        })
    },
    created() {
        this.initialSet()
    },
    mounted() {
        this.initialMountedSet()
    },
    methods: {
        showS(){
            // this.showNum++
            // if(this.showNum>=5){
            //     this.scriptLoaded()
            //     this.showNum = 0
            // }
        },
        getParams(){
            let orderInfo = this.$refs.OrderCoupon.getOrderAmt()
            let cartIds = []
            orderInfo.orderAmt?.orderItems.map(res=>{
                cartIds.push(res.id)
            })
            let params = {
                couponSpuIds:orderInfo.suitSpu,
                cartIds:cartIds,
                orderFrom:"cart",
                orderItems:orderInfo.orderAmt.orderItems,
                redirectUrl:window.location.href,
            }
            if(orderInfo.checkInfo?.code){
                params.code = orderInfo.checkInfo.code
                params.couponAmount = orderInfo.checkInfo.couponDiscountAmount
            }
            return JSON.stringify(params)
        },
        expressPaypal(){
            let fbq = {
                content_category: 'product',
                content_type: 'product_group',
                content_ids: [],
                contents: [],
                num_items: 0,
            };
            let orderInfo = this.$refs.OrderCoupon.getOrderAmt()
            let cartIds = []
            let tiktokItems = [];
            orderInfo.orderAmt?.orderItems.map(res=>{
                fbq.content_ids.push(res.spuId)
                fbq.contents.push(res)
            })
            // let params = {
            //     couponSpuIds:orderInfo.suitSpu,
            //     // cartIds:orderInfo.orderAmt.spuId,
            //     cartIds:cartIds,
            //     orderFrom:"cart",
            //     orderItems:orderInfo.orderAmt.orderItems,
            //     redirectUrl:window.location.href,
            // }
            // if(orderInfo.checkInfo?.code){
            //     params.code = orderInfo.checkInfo.code
            //     params.couponAmount = orderInfo.checkInfo.couponDiscountAmount
            // }
            let params = this.getParams()
            this.goods.list.map(item=>{
                item.cartItems.map(res=>{
                    tiktokItems.push({
                        content_type: 'product',
                        quantity: res.quantity,
                        content_id: res.productCode,
                        content_name: res.productName,
                        price: res.skuPrice / 100,
                        value: (res.skuPrice / 100) * res.quantity,
                        currency: 'USD',
                    })
                })
            })
            this.$api.bag.setBagStock().then(response => {
                if(response.code == 'success') {
                    this.$api.order.createExpressPaypalOrder({data:params}).then(response=>{
                        const res = response?.result || {};
                        // fb
                        this.$fnApi.buried.fbq({
                            type: 'InitiateCheckout',
                            params: {
                                currency: 'USD',
                                value: (res.totalAmt/100).toFixed(2),
                                ...fbq
                            }
                        }, this)
                        //tiktok
                        this.$fnApi.buried.tiktok({
                            type: 'InitiateCheckout',
                            params: {
                                contents: tiktokItems,
                                currency: 'USD',
                                value:(res.totalAmt/100).toFixed(2)
                            }
                        })
                        window.location.href = res.linkHref || res.redirect_url
                    })
                }else{
                    this.loading = false
                }
            })
        },
        checkoutBtn(){
            this.loading = true
            this.$refs.BagSubmit.handleSubmit()
        },
        // 商品数据
        setGoodsData(obj) {
            this.goods = obj.goods
            this.amt = obj.amt
            this.goodsInfo = obj
            if(this.stripe)this.scriptLoaded()
        },
        // 顶部编辑
        setBagEditData(obj) {
            this.edit = obj
        },
        emitEditDelete() {
            const ref = this.$refs.BagGoods;
            ref && ref.handleDelete({
                id: this.edit.id
            })
        },
        // emit
        getBagAjax() { // 购物车数据
            const ref = this.$refs.BagGoods;
            ref && ref.getBagAjax()
        },
        setChooseProduct(obj) { // 选择购物车商品
            const ref = this.$refs.BagGoods;
            ref && ref.setChooseProduct(obj)
        },
        setCusData(obj) { // 定制数据
            this.cus = {...this.cus, ...obj?.popup, ...obj}
        },
        couponChange(obj) { // 优惠券变更
            const amtDetail = obj.amtDetail || {};
            if(!amtDetail.code) {
                this.amt = this.goodsInfo.amt
            } else {
                this.amt = amtDetail
            }
        },
        // SEO HEAD
        setHead() {
            const head = this.$ctxApi.seoHead.initial({
                title: this.$translate('Shopping Bag'),
                keywords: this.$translate('Shopping Bag'),
                description: this.$translate('Shopping Bag'),
                image: 'https://cdn2.selleroa.com/yfn-upload/review/1694078645122.png'
            });
            if(process.client){
                head.script = [{
                    type: 'text/javascript',
                    async: true,
                    defer: true,
                    src: 'https://js.stripe.com/v3/',
                    callback: () => (this.scriptLoaded())
                }]
            }
            return {
                ...head
            }
        },
        // scriptUpdate(){
        //     let orderInfo = this.$refs.OrderCoupon.getOrderAmt()
        //     this.amount = this.goodsInfo.amt.useCurrencyCartTotalAmt
        //     //防止优惠券重复影响快捷支付显示
        //     if((orderInfo?.checkInfo?.code&&this.amount == orderInfo.checkInfo.displayCartTotalAmt*100)||!this.elements){
        //         return
        //     }
        //     if(orderInfo?.checkInfo?.code){
        //         this.amount = orderInfo.checkInfo.displayCartTotalAmt*100
        //     }
        //     const options = {
        //         mode: 'payment',
        //         amount: this.amount,
        //         currency: this.currency.code.toLowerCase(),
        //     };
        //     var expressCheckoutElement = this.elements.getElement('expressCheckout');
        //     expressCheckoutElement.update(options);
        // },
        handleCode(data){
            console.log(data.info?.freeShippingType)
            if(data?.info?.freeShippingType){
                this.goodsInfo.amt.freeShippingType = data.info.freeShippingType
            }
        },
        scriptLoaded(isCoupon){
            try {
                if(process.client){
                    let isCard = true
                    const expressCheckoutDiv = document.getElementById('express-checkout-element');
                    this.goodsInfo.allInfo?.shopCart?.map(item=>{
                        item?.cartItems?.map(res=>{
                            if(res?.backCat?.id!="1666159803110Di4rFmid"&&res?.backCat?.id!="1658901076045ufAY5BPO")isCard = false
                        })
                    })
                    let orderInfo = this.$refs?.OrderCoupon?.getOrderAmt()
                    let amount = this.$refs?.OrderCoupon?.coupon?.info?.code?orderInfo.checkInfo.cartTotalAmt:this.goodsInfo?.amt?.cartTotalAmt
                    //防止优惠券重复影响快捷支付显示
                    if(!isCoupon&&this.amount == amount){
                        return
                    }else if(isCard){
                        //如果商品全是贺卡
                        expressCheckoutDiv.style.display = 'none';
                        return
                    }
                    this.amount = amount
                    //pk_live_BUfBVA6AMFNfls8q9x4kQWtP  pk_test_TYooMQauvdEDq54NiTphI7jx
                    this.stripe = Stripe('pk_live_BUfBVA6AMFNfls8q9x4kQWtP');
                    const options = {
                        mode: 'payment',
                        amount: Number(this.amount),
                        currency: this.currency.code.toLowerCase(),
                    };
                    const elements = this.stripe.elements(options);
                    this.elements = elements;
                    const expressCheckoutElement = elements.create('expressCheckout',{
                        buttonHeight:41,
                        paymentMethods:{
                            amazonPay:'auto',
                            applePay:'always',
                            googlePay:'always',
                        }
                    });
                    expressCheckoutElement.mount('#express-checkout-element');

                    expressCheckoutDiv.style.visibility = 'none';

                    expressCheckoutElement.on('ready', ({availablePaymentMethods}) => {
                        if (!availablePaymentMethods) {
                            // No buttons will show
                        } else {
                            // Optional: Animate in the Element
                            expressCheckoutDiv.style.visibility = 'block';
                        }
                    });

                    expressCheckoutElement.on('click', (event) => {
                        const options = {
                            emailRequired: true,
                            phoneNumberRequired: true,
                        };
                        event.resolve(options);
                    });
                    // 点击付款后的事件
                    const handleError = (error) => {
                    const messageContainer = document.querySelector('#error-message');
                        messageContainer.textContent = error.message;
                    }

                    expressCheckoutElement.on('confirm', async (event) => {
                        const {error: submitError} = await elements.submit();
                        if (submitError) {
                            handleError(submitError);
                            return;
                        }
                        // Create the PaymentIntent and obtain clientSecret
                        let params = this.getParams()

                        const res = await this.$api.order.createIntent({data:params})
                        
                        const clientSecret = res.result;

                        const {error} = await this.stripe.confirmPayment({
                            // `elements` instance used to create the Express Checkout Element
                            elements,
                            // `clientSecret` from the created PaymentIntent
                            clientSecret,
                            confirmParams: {
                                return_url: `${window.location.origin}/paysuccess?id=${res.orderId}`,
                            },
                        });

                        if (error) {
                            // This point is only reached if there's an immediate error when
                            // confirming the payment. Show the error to your customer (for example, payment details incomplete)
                            handleError(error);
                        } else {
                            // The payment UI automatically closes with a success animation.
                            // Your customer is redirected to your `return_url`.
                        }
                    });
                }
            } catch (error) {
                if (!this.stripe && this.reNum++ < 4) {
                    // console.log('reload');
                    setTimeout(() => {
                        this.scriptLoaded('load');
                    }, 1000);
                }
            }
        },
        // 初始化
        initialSet() {
            this.currency = this.$storage.get('currency') || {}
            this.language = this.$storage.get('language');
            this.middleEmit.fn = (fn, options, callback) => {
                this[fn] && this[fn](options)
            }
        },
        initialMountedSet() {
            this.hasLogin = this.$login()
        }
    },
}
